<template>
	<section class="numbersInfoText" :class="theme">
		<div class="container">
			<div class="title"></div>
			<div class="left">
				<div class="item">
					<div class="caption">{{ d.title }}</div>
					<div class="txt" v-html="d.text"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "NumbersInfo2",
	props: ["theme"],
	computed: {},
};
</script>

<style lang="scss">
.numbersInfoText {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		grid-template-columns: repeat();
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.left {
			display: block;
			grid-template: auto / 1fr 1fr 1fr;
			grid-gap: 30px;
			@media (max-width: $tl) {
				margin-top: 40px;
				@media (max-width: $ts) {
					margin-top: 0;
				}
			}
			@media (max-width: $ts) {
				display: block;
			}
			.item {
				text-decoration: none;
				@media (max-width: $ts) {
					// display: flex;
				}
				& + .item {
					@media (max-width: $ts) {
						margin-top: 45px;
					}
				}
				.caption {
					@include rt;
					color: $cred1;
					@media (max-width: $ts) {
						font-size: 27px;
					}
				}
				.txt {
					@include t4;
					margin-top: 10px;
					@media (max-width: $ts) {
						// width: 50%;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>