<template>
	<section class="anchormenu">
		<div class="container">
			<Flickity
				ref="captionsSlider"
				:options="captionsSliderOptions"
				class="captionsSlider"
				:class="{ gray: theme == 'gray' }"
			>
				<div
					class="slide"
					v-for="(item, index) in slides"
					:key="index"
					@click="onItemClick(item.link)"
				>
					<div class="num">{{ String(index + 1).padStart(2, "0") }}</div>
					<div class="caption">{{ item.title }}</div>
				</div>
			</Flickity>
		</div>
		<div class="fixedCont" :class="{ active: fixed, gray: theme == 'gray' }">
			<div class="container">
				<Flickity
					ref="fixedCaptionsSlider"
					:options="captionsSliderOptions"
					class="captionsSlider mob"
					:class="{ gray: theme == 'gray' }"
				>
					<div
						class="slide"
						v-for="(item, index) in slides"
						:key="index"
						@click="onItemClick(item.link)"
					>
						<div class="num">{{ String(index + 1).padStart(2, "0") }}</div>
						<div class="caption">{{ item.title }}</div>
					</div>
				</Flickity>
			</div>
		</div>
	</section>
</template>

<script>
import Flickity from "vue-flickity";
export default {
	name: "AnchorMenu",
	props: ["theme"],
	components: {
		Flickity,
	},
	data() {
		return {
			captionsSliderOptions: {
				// prevNextButtons: false,
				cellAlign: "center",
				contain: true,
				pageDots: false,
				// groupCells: true,
			},

			currentIndex: 0,
			fixed: false,
			top: 10000,
		};
	},
	computed: {
		slides() {
			let a = [];
			for (const key in this.d.list) {
				if (Object.hasOwnProperty.call(this.d.list, key)) {
					const element = this.d.list[key];
					a.push(element);
				}
			}
			return a;
		},
	},
	methods: {
		onScroll() {
			if (window.scrollY > this.top + 132) {
				this.fixed = true;
			} else {
				this.fixed = false;
			}
		},
		offset(el) {
			var rect = el.getBoundingClientRect(),
				scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
		},
		onItemClick(id) {
			let ele = document.getElementById(id);
			if (ele) {
				const rect = ele.getBoundingClientRect();
				const top = rect.top + window.scrollY - 30;
				window.scrollTo({
					top: top,
					behavior: "smooth",
				});
			}
		},
	},
	mounted() {
		this.$refs.captionsSlider.on("change", (index) => {
			this.currentIndex = index;
			this.$refs.fixedCaptionsSlider.select(index);
		});
		this.$refs.fixedCaptionsSlider.on("change", (index) => {
			this.currentIndex = index;
			this.$refs.captionsSlider.select(index);
		});
		this.$refs.captionsSlider.on(
			"staticClick",
			(event, pointer, cellElement, cellIndex) => {
				this.currentIndex = cellIndex + 1;
				this.$refs.captionsSlider.select(cellIndex);
			}
		);
		this.$refs.fixedCaptionsSlider.on(
			"staticClick",
			(event, pointer, cellElement, cellIndex) => {
				this.currentIndex = cellIndex + 1;
				this.$refs.captionsSlider.select(cellIndex);
			}
		);
		window.addEventListener("scroll", this.onScroll);
		this.top = this.offset(this.$refs.captionsSlider.$el).top;
	},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.bmar {
	margin-bottom: 66px !important;
}
.anchormenu {
	position: relative;
	z-index: 10;
	.container {
		@include container;

		.sliderBody {
			.slides {
				position: relative;
				.slide {
					display: flex;
					align-items: flex-end;
					justify-content: flex-start;
					min-height: 528px;
					img {
						position: absolute;
						z-index: 1;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.txtWrp {
						position: relative;
						z-index: 2;
						background-color: $cwhite;
						margin: 10px;
						padding: 30px 30px 20px;
						width: 50%;
						.caption {
							@include h1tabl;
						}
						p {
							@include t3;
						}
						.watchBtn {
							margin-top: 45px;
							padding: 0 48px 0 0;
							border: 2px solid $cgray4;
							line-height: 58px;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							text-align: center;
							letter-spacing: 0.03em;
							text-transform: uppercase;
							color: #000;
							width: 247px;
							cursor: pointer;

							position: relative;
							.ic-icplay-2 {
								font-size: 24px;
								position: absolute;
								top: 50%;
								right: 20px;
								transform: translate(0, -50%);
							}
						}
					}
				}
			}
		}
		.captionsSlider {
			background-color: $cgraybg1;
			&.mob{
				@media (max-width: $ts) {
					display: none;
				}
			}
			.flickity-button {
				padding: 0;
				background-color: $cgraybg1;
				// background-color: red;
				transform: unset;
				border-radius: 0;
				width: 40px;
				height: unset;
				&:disabled {
					opacity: 0;
				}
				.flickity-button-icon {
					width: 15px;
					left: unset;
					right: unset;
					top: unset;
					bottom: unset;
					position: static;
				}
				.arrow {
					fill: $cgray7;
				}
			}
			.previous {
				left: 0;
				bottom: 0;
				top: 0;
				border-right: 1px solid $cgray7;
			}
			.next {
				right: 0;
				bottom: 0;
				top: 0;
				border-left: 1px solid $cgray7;
			}
			.slide {
				height: 66px;
				width: 186px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				padding: 0 15px;
				&.is-selected {
					background-color: $cblacktxt;
				}
				.num {
					@include t4;
					color: $cgray3;
				}
				.caption {
					@include t4;
					color: $cgray7;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					word-break: normal;
					width: 100%;
				}
			}
			&.gray {
				background-color: $cgray6;
			}
		}
	}
	.fixedCont {
		background-color: $cgraybg1;
		position: fixed;
		top: -66px;
		right: 0;
		left: 0;
		transition: 0.3s ease;
		&.gray {
			background-color: $cgray6;
		}
		&.active {
			top: 0;
		}
	}
	&.gray {
		.fixedCont {
			&.gray {
				background-color: $cgray6;
			}
		}
		.captionsSlider {
			background-color: $cgraybg1;
			.flickity-button {
				background-color: $cgray6;
				.arrow {
					fill: $cblacktxt;
				}
			}
			.previous {
				border-right: 1px solid $cgray5;
			}
			.next {
				border-left: 1px solid $cgray5;
			}
			.slide {
				&.is-selected {
					background-color: transparent;
					position: relative;
					&::before {
						content: "";
						bottom: 0;
						right: 0;
						left: 0;
						height: 3px;
						background-color: $cred1;
						position: absolute;
					}
				}
				.num {
					@include t4;
					color: $cgray3;
				}
				.caption {
					color: $cblacktxt;
				}
			}
		}
	}
}
</style>