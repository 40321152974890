<template>
    <section class="MainSliderBanner" :class="theme">
        <div class="container">
            <div class="sliderBody">
                <div class="slides">
                    <div class="slide">
                        <img :src="path(bgImage)" :alt="path(bgImage)" />
                        <div class="txtWrp">
                            <div v-if="d.title" class="caption">
                                {{ d.title }}
                            </div>
                            <div v-if="d.text" v-html="d.text"></div>
                            <div class="watchBtn" @click="modalShown = true">
                                {{ d.btn_name }}
                                <span class="ic-icplay-2"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <yt-modal
            class="ytmodal"
            v-if="modalShown"
            :videoId="videoId"
            @close="modalShown = !modalShown"
        ></yt-modal>
    </section>
</template>

<script>
import YtModal from "../Parts/YtModal.vue";

export default {
    components: { YtModal },
    name: "MainSliderBanner",
    props: ["theme"],
    data() {
        return { modalShown: false, width: 0 };
    },
    computed: {
        videoId() {
            return this.d.link.replace(/(.*?)watch\?v=/, "");
        },
        bgImage() {
            if (this.width > 768) return this.d.image;
            else return this.d.image_mob;
        }
    },
    created() {
        this.getW();
        window.addEventListener("resize", this.getW);
    },
    destroyed() {
        window.removeEventListener("resize", this.getW);
    },
    methods: {
        getW() {
            this.width = window.innerWidth;
        }
    }
};
</script>

<style lang="scss">
.ytmodal {
    z-index: 1000;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.bmar {
    margin-bottom: 66px !important;
}
.MainSliderBanner {
    position: relative;
    z-index: 11;
    .container {
        @include container;

        .sliderBody {
            .slides {
                position: relative;
                .slide {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    min-height: 528px;
                    img {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .txtWrp {
                        position: relative;
                        z-index: 2;
                        background-color: $cwhite;
                        margin: 10px;
                        padding: 30px 30px 20px;
                        // width: 50%;
                        @media (max-width: $tl) {
                            // width: 80%;
                        }
                        @media (max-width: $tm) {
                            width: 100%;
                        }
                        .caption {
                            @include h1tabl;
                            @media (max-width: $tl) {
                                @include h2;
                            }
                            @media (max-width: $tm) {
                                @include h2mob;
                            }
                        }
                        p {
                            @include t3;
                        }
                        .watchBtn {
                            // margin-top: 45px;
                            padding: 0 48px 0 0;
                            border: 2px solid $cgray4;
                            line-height: 58px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            text-align: center;
                            letter-spacing: 0.03em;
                            text-transform: uppercase;
                            color: #000;
                            width: 247px;
                            cursor: pointer;
                            position: relative;
                            @media (max-width: $tm) {
                                width: 100%;
                            }
                            .ic-icplay-2 {
                                font-size: 24px;
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                transform: translate(0, -50%);
                            }
                        }
                    }
                }
            }
        }
        .captionsSlider {
            background-color: $cgraybg1;
            .flickity-button {
                padding: 0;
                background-color: $cgraybg1;
                // background-color: red;
                transform: unset;
                border-radius: 0;
                width: 40px;
                height: unset;
                &:disabled {
                    opacity: 0;
                }
                .flickity-button-icon {
                    width: 15px;
                    left: unset;
                    right: unset;
                    top: unset;
                    bottom: unset;
                    position: static;
                }
                .arrow {
                    fill: $cgray7;
                }
            }
            .previous {
                left: 0;
                bottom: 0;
                top: 0;
                border-right: 1px solid $cgray7;
            }
            .next {
                right: 0;
                bottom: 0;
                top: 0;
                border-left: 1px solid $cgray7;
            }
            .slide {
                height: 66px;
                width: 186px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0 15px;
                &.is-selected {
                    background-color: $cblacktxt;
                }
                .num {
                    @include t4;
                    color: $cgray3;
                }
                .caption {
                    @include t4;
                    color: $cgray7;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-break: normal;
                    width: 100%;
                }
            }
            &.gray {
                background-color: $cgray6;
            }
        }
    }
    .fixedCont {
        background-color: $cgraybg1;
        position: fixed;
        top: -66px;
        right: 0;
        left: 0;
        transition: 0.3s ease;
        &.gray {
            background-color: $cgray6;
        }
        &.active {
            top: 0;
        }
    }
    &.gray {
        .fixedCont {
            &.gray {
                background-color: $cgray6;
            }
        }
        .captionsSlider {
            background-color: $cgraybg1;
            .flickity-button {
                background-color: $cgray6;
                .arrow {
                    fill: $cblacktxt;
                }
            }
            .previous {
                border-right: 1px solid $cgray5;
            }
            .next {
                border-left: 1px solid $cgray5;
            }
            .slide {
                &.is-selected {
                    background-color: transparent;
                    position: relative;
                    &::before {
                        content: "";
                        bottom: 0;
                        right: 0;
                        left: 0;
                        height: 3px;
                        background-color: $cred1;
                        position: absolute;
                    }
                }
                .num {
                    @include t4;
                    color: $cgray3;
                }
                .caption {
                    color: $cblacktxt;
                }
            }
        }
    }
}
</style>
