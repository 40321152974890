<template>
	<section class="containerSizeImgBanner" :class="theme">
		<div
			class="container"
			:style="{
				backgroundImage: `url(${path(d.image)}`,
			}"
		>
			<router-link :to="d.link" class="lnk"
				>{{ d.title }}
				<span class="ic-icarrow-right"></span>
			</router-link>
		</div>
	</section>
</template>

<script>
export default {
	name: "ContainerSizeImgBanner",
	props: ["theme"],
};
</script>

<style lang="scss">
.containerSizeImgBanner {
	padding: 30px 0;
	.container {
		@include container;
		padding-left: 0;
		padding-right: 0;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 440px;

		a {
			width: 25%;
			flex-shrink: 0;
			min-height: 148px;
			padding: 20px 20px 50px 20px;
			background-color: $cwhite;
			position: relative;

			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 120%;
			color: $cblacktxt;
			text-decoration: none;

			@media (max-width: $tl) {
				width: 33.33%;
			}
			@media (max-width: $ts) {
				width: 50%;
			}
			@media (max-width: $m) {
				width: 100%;
			}

			.ic-icarrow-right {
				font-size: 24px;
				position: absolute;
				bottom: 20px;
				right: 20px;
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>