<template>
	<section class="contactMenu" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<div class="cmwrp">
					<div class="menuWrp">
						<div class="menu">
							<router-link
								v-for="(item, index) in d.list"
								:key="index"
								:to="item.link"
							>
								{{ item.title }}
								<span class="ic-icarrow-right"></span>
							</router-link>
						</div>
						<div class="tell">
							<a :href="`tel:${d.phone}`" class="tl">{{ d.phone }}</a>
							<div class="st" v-html="d.text"></div>
						</div>
					</div>
					<img class="rimg" :src="path(d.image)" :alt="path(d.image)" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "ContactsMenu",
	props: ["theme", "textprop"],
};
</script>

<style lang="scss">
.contactMenu {
	padding: 60px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.cmwrp {
				display: grid;
				grid-template-columns: 2fr 1fr;
				grid-gap: 30px;
				@media (max-width: $tm) {
					display: block;
				}
				.rimg {
					object-fit: cover;
					@media (max-width: $tm) {
						width: 100%;
						height: 426px;
					}
				}
				.menu {
					a {
						display: block;
						padding: 15px 0;
						@include h4;
						text-decoration: none;
						padding-right: 35px;
						position: relative;
						.ic-icarrow-right {
							position: absolute;
							top: 50%;
							right: 0;
							font-size: 20px;
							transform: translate(0, -50%);
						}
						& + a {
							border-top: 1px solid #d4d6e7;
						}
						&:hover {
							color: $cred1;
						}
					}
				}
				.tell {
					margin-top: 60px;
					a {
						font-style: normal;
						font-weight: 300;
						font-size: 34px;
						line-height: 88%;
						letter-spacing: -0.025em;
						text-decoration-line: underline;
						color: #e1251b;
					}
					.st {
						margin-top: 10px;
						@include t3l;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>