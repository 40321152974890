<template>
	<main>
		<transition name="fade">
			<div v-if="isLoaded">
				<div class="titleContainer">
					<h1 class="landingPageTitle">{{ translate.title }}</h1>
				</div>
				<template v-for="(item, index) in constructor">
					<component
						v-if="item.visibility == '1'"
						:key="item.component + index"
						:is="item.component"
						:d="item.content"
						:id="item.widget_id"
						:textprop="item.component"
						:theme="item.content.theme || null"
					></component>
				</template>
			</div>
		</transition>
	</main>
</template>

<script>
import AdvantagesSection1 from "../components/Sections/AdvantagesSection1.vue";
import AdvItemsSection from "../components/Sections/AdvItemsSection.vue";
import AnchorMenu from "../components/Sections/AnchorMenu.vue";
import CompanyInfoAccordion from "../components/Sections/CompanyInfoAccordion.vue";
import ContactsMenu from "../components/Sections/ContactsMenu.vue";
import ContainerSizeImgBanner from "../components/Sections/ContainerSizeImgBanner.vue";
import FaqSection from "../components/Sections/FaqSection.vue";
import FirstScreen from "../components/Sections/FirstScreen.vue";
import FormsLinks from "../components/Sections/FormsLinks.vue";
import FourColLinks from "../components/Sections/FourColLinks.vue";
import FullWidthImgSection from "../components/Sections/FullWidthImgSection.vue";
import HistorySlider from "../components/Sections/HistorySlider.vue";
import ImgAndTxtLinks from "../components/Sections/ImgAndTxtLinks.vue";
import ImgBannerLeftTxt from "../components/Sections/ImgBannerLeftTxt.vue";
import ImgCorusel from "../components/Sections/ImgCorusel.vue";
import ImgSlideViever from "../components/Sections/ImgSlideViever.vue";
import InfoSlider from "../components/Sections/InfoSlider.vue";
import InfoSliderLanding from "../components/Sections/InfoSliderLanding.vue";
import LeftImgTxtBlock from "../components/Sections/LeftImgTxtBlock.vue";
import LetersChapters from "../components/Sections/LetersChapters.vue";
import MainImageBanner from "../components/Sections/MainImageBanner.vue";
import MainSliderBanner from "../components/Sections/MainSliderBanner.vue";
import MessageLinks from "../components/Sections/MessageLinks.vue";
import NewsSectionLanding from "../components/Sections/NewsSectionLanding.vue";
import NumberInfos from "../components/Sections/NumberInfos.vue";
import NumberInfosText from "../components/Sections/NumberInfosText.vue";
import PopularChapters from "../components/Sections/PopularChapters.vue";
import PressSection from "../components/Sections/PressSection.vue";
import SeeAlsoSection from "../components/Sections/SeeAlsoSection.vue";
import TextLeftCol from "../components/Sections/TextLeftCol.vue";
import TextTabs from "../components/Sections/TextTabs.vue";
import ThreeColLinks from "../components/Sections/ThreeColLinks.vue";
import ThreeColSlimLinks from "../components/Sections/ThreeColSlimLinks.vue";
import VisionDocs from "../components/Sections/VisionDocs.vue";
export default {
	components: {
		"main-banner": FirstScreen,
		"sections-often-searched": PopularChapters,
		"what-is-biopharma": FaqSection,
		"video-slider": InfoSlider,
		accordion: AdvantagesSection1,
		"accordion-2": CompanyInfoAccordion,
		"image-and-link": FullWidthImgSection,
		"history-slider": HistorySlider,
		"see-also-main": SeeAlsoSection,
		"video-banner": MainSliderBanner,
		AnchorMenu,
		drugs: LetersChapters,
		"slider-tabs": TextTabs,
		"see-also-type-1": ThreeColSlimLinks,
		"list-title-text-link": AdvItemsSection,
		"video-banner-2": ImgBannerLeftTxt,
		"image-title-text-link": ImgAndTxtLinks,
		"see-also-type-2": FourColLinks,
		"full-image": MainImageBanner,
		"biopharma-socials": PressSection,
		"biopharma-numbers": NumberInfos,
		"text-and-image": LeftImgTxtBlock,
		sections: ThreeColLinks,
		"news-2": NewsSectionLanding,
		news: VisionDocs,
		"title-text-link": MessageLinks,
		slider: ImgCorusel,
		"image-and-link-2": ContainerSizeImgBanner,
		"video-slider-2": InfoSliderLanding,
		"form-links": FormsLinks,
		"text-2": NumberInfosText,
		"title-text-link-quote": MessageLinks,
		ContactsMenu,
		"slider-2": ImgSlideViever,
		"text-3-column": TextLeftCol,
		"text-element": TextLeftCol,
		"text-and-image-2": TextLeftCol,
		"simple-text": TextLeftCol,
	},
	name: "LandingPage",
	data() {
		return {
			isLoaded: false,
			constructor: null,
			model: null,
			translate: null,
		};
	},
	methods: {},
	created() {
		this.axios
			.post("/api/page/get-by-slug", {
				lang: this.locale,
				slug: this.$route.params.slug,
			})
			.then((response) => {
				this.constructor = response.data.data.constructor;
				this.model = response.data.data.model;
				this.translate = response.data.data.translate;
				document.title = response.data.data.translate.meta_title || response.data.data.translate.title;
				this.isLoaded = true;
			})
			.catch(({ response }) => {
				this.solveStatus(response.status);
			});
	},
};
</script>

<style lang="scss">
.titleContainer {
	@include container;
	.landingPageTitle {
		@include h1tabl;
		@media (max-width: $tl) {
			@include h1mob;
		}
	}
}
</style>