<template>
	<section class="leftImgTxtBlock">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<img class="leftImg" :src="path(d.image)" :alt="path(d.image)" />
				<div class="txt" v-html="d.text"></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "LeftImgTxtBlock",
};
</script>

<style lang="scss">
.leftImgTxtBlock {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 2fr 1fr;
			@media (max-width: $tl) {
				margin-top: 40px;
				display: block;
			}
			.leftImg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
			.txt {
				padding: 30px;
				@media (max-width: $m) {
					padding: 10px;
				}
				background-color: $cgray6;
				@include t3l;
				p {
					margin-top: 0;
				}
			}
		}
	}
}
</style>