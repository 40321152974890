<template>
	<section class="newsSection" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<news-item
					v-for="(item, index) in d.list"
					:key="index"
					:d="item"
				></news-item>

				<div class="lnkWrp" :style="linkPos">
					<router-link
						target="_blank"
						:to="{
							name: 'news',
							params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
						}"
						class="button2"
						:class="{ left: this.linkverse == 'back' }"
						>{{ $t("moreNews") }}
						<span
							v-if="this.linkverse == 'back'"
							class="ic-icarrow-left"
						></span>
						<span v-else class="ic-icarrow-right"></span
					></router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NewsItem from "../Parts/NewsItem.vue";
export default {
	components: { NewsItem },
	name: "NewsSectionLanding",
	props: ["theme", "linkverse"],
	computed: {
		linkPos() {
			return this.linkverse == "back" ? { justifyContent: "flex-start" } : null;
		},
	},
};
</script>

<style lang="scss">
.newsSection {
	padding: 30px 0;
	background-color: $cgray7;
	&.light {
		background-color: transparent;
	}
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.lnkWrp {
				margin-top: 15px;
				display: flex;
				justify-content: flex-end;
				.button2 {
					width: 250px;
				}
			}
		}
	}
}
</style>