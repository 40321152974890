<template>
	<section class="imgBannerLeftTxt" :class="theme">
		<div class="container">
			<div
				class="img"
				:style="{
					backgroundImage: `url(${path(d.image)}`,
				}"
			>
				<div class="txtWrp">
					<div>
						<h2>{{ d.title }}</h2>
						<div v-html="d.text"></div>
						<div class="watchBtn" @click="modalShown = true">
							{{ d.btn_name }}
							<span class="ic-icplay-2"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<yt-modal
			v-if="modalShown"
			:videoId="videoId"
			@close="modalShown = !modalShown"
		></yt-modal>
	</section>
</template>

<script>
import YtModal from "../Parts/YtModal.vue";
export default {
	components: { YtModal },
	name: "ImgBannerLeftTxt",
	props: ["theme"],
	data() {
		return { modalShown: false };
	},
	computed: {
		videoId() {
			return this.d.link.replace(/(.*?)watch\?v=/, "");
		},
	},
};
</script>

<style lang="scss">
.imgBannerLeftTxt {
	padding: 30px 0;
	.container {
		@include container;
		@media (max-width: $m) {
			flex-direction: column;
			justify-content: flex-end;
		}
		.img {
			height: 580px;
			display: flex;
			background-size: cover;
			background-position: center;
			@media (max-width: $m) {
				flex-direction: column;
				justify-content: flex-end;
			}
			.txtWrp {
				background: rgba(57, 59, 75, 0.75);
				backdrop-filter: blur(15px);
				padding: 30px;
				display: flex;
				align-items: flex-end;
				padding-bottom: 30px;
				width: 300px;
				@media (max-width: $m) {
					width: 100%;
				}
				h2 {
					@include h1mob;
					color: $cwhite;
					margin: 0;
					
				}
				p {
					@include t3l;
					margin: 10px 0 0;
					color: $cwhite;
				}
				.watchBtn {
					padding: 0 48px 0 0;
					margin-top: 35px;
					border: 2px solid $cwhite;
					line-height: 58px;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					text-align: center;
					letter-spacing: 0.03em;
					text-transform: uppercase;
					color: $cwhite;
					cursor: pointer;

					position: relative;
					.ic-icplay-2 {
						font-size: 24px;
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translate(0, -50%);
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>