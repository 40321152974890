<template>
	<section class="ImgAndTxtLinks" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="lnksWrp">
				<template v-for="(item, index) in d.list">
					<template v-if="item.link">
						<a
							v-if="isExtUrl(item.link)"
							:key="index"
							:href="item.link"
							class="lnkItem"
						>
							<img :src="path(item.image)" :alt="path(item.image)" />
							<div class="txt">
								<div class="caption">{{ item.title }}</div>
								<div class="sub" v-html="item.text"></div>
								<span class="ic-icarrow-right"></span>
							</div>
						</a>
						<router-link v-else :key="index" :to="item.link" class="lnkItem">
							<img :src="path(item.image)" :alt="path(item.image)" />
							<div class="txt">
								<div class="caption">{{ item.title }}</div>
								<div class="sub" v-html="item.text"></div>
								<span class="ic-icarrow-right"></span>
							</div>
						</router-link>
					</template>
					<div v-else :key="index" :to="item.link" class="lnkItem">
						<img :src="path(item.image)" :alt="path(item.image)" />
						<div class="txt">
							<div class="caption">{{ item.title }}</div>
							<div class="sub" v-html="item.text"></div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "ImgAndTxtLinks",
	props: ["theme"],
};
</script>

<style lang="scss">
.ImgAndTxtLinks {
	padding: 30px 0;
	.container {
		@include container;
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.lnksWrp {
			margin-top: 40px;
			display: grid;
			grid-template: auto / 1fr 1fr;
			@media (max-width: $tl) {
				display: block;
			}
			.lnkItem {
				display: grid;
				grid-template: auto / 1fr 1fr;
				text-decoration: none;
				background-color: $cwhite;
				@media (max-width: $ts) {
					display: block;
				}
				img {
					height: 270px;
					width: 100%;
					object-fit: cover;
					min-height: 100%;
				}
				.txt {
					padding: 15px 10px;
					.caption {
						@include h4;
						margin-bottom: 20px;
					}
					.sub {
						@include t4;
						margin-bottom: 25px;
					}
					.ic-icarrow-right {
						font-size: 20px;
						color: #000;
					}
				}
			}
			.lnkItem:nth-child(3),
			.lnkItem:nth-child(4) {
				@media (min-width: $tl) {
					img {
						order: 1;
					}
				}
			}
			.lnkItem:nth-child(even) {
				@media (max-width: $tl) and (min-width: $ts) {
					img {
						order: 1;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>