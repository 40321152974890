<template>
	<section class="messageLinks" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<div class="item">
					<template v-if="textprop == 'title-text-link-quote'">
						<h2>{{ d.title2 }}</h2>
						<div v-html="d.text"></div>
						<div class="qt" v-html="d.quote"></div>
						<router-link :to="d.link" class="lnk" target="_blank">
							{{ d.btn_name }}
							<span class="ic-icarrow-right"></span>
						</router-link>
					</template>
					<template v-else>
						<div v-html="d.text"></div>
						<router-link :to="d.link" class="lnk" target="_blank">
							{{ d.btn_name }}
							<span class="ic-icarrow-right"></span>
						</router-link>
					</template>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "messageLinks",
	props: ["theme", "textprop"],
};
</script>

<style lang="scss">
.messageLinks {
	padding: 60px 0;
	@media (max-width: $tm) {
		padding: 30px 0 0;
	}
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.item {
				width: 66.66%;
				position: relative;
				margin-left: auto;
				@media (max-width: $tl) {
					width: unset;
				}
				h2 {
					@include rt;
					color: $cred1;
				}
				p {
					@include t2l;
					margin: 0;
					& + p {
						margin-top: 16px;
					}
				}
				.qt {
					margin-top: 40px;
					p {
						@include t3l;
					}
				}
				.lnk {
					margin-top: 100px;
					display: block;
					padding: 36px 68px 36px 0;
					box-shadow: inset 0px 1px 0px #d3d6e7;
					text-decoration: none;
					@include h4;
					position: relative;
					.ic-icarrow-right {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translate(0, -50%);
						font-size: 28px;
						color: $cred1;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>