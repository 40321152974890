<template>
	<section class="letersChapters" :class="theme">
		<div class="container">
			<div class="title">
				<h2>
					{{ d.title }}
				</h2>
			</div>
			<div class="right">
				<template v-for="(item, index) in d.list">
					<router-link
						target="_blank"
						v-if="item.link"
						:to="item.link"
						class="leterLinkItem"
						:key="index"
					>
						<div class="leter">{{ item.symbol }}</div>
						<div class="bd">
							<div class="caption">
								{{ item.title }}
							</div>
							<div class="txt" v-html="item.text"></div>
							<span v-if="d.btn_name && d.link" class="ic-icarrow-right"></span>
						</div>
					</router-link>
					<div v-else class="leterLinkItem" :key="index">
						<div class="leter">{{ item.symbol }}</div>
						<div class="bd">
							<div class="caption">
								{{ item.title }}
							</div>
							<div class="txt" v-html="item.text"></div>
						</div>
					</div>
				</template>

				<div v-if="d.btn_name && d.link" class="lnkWrp">
					<router-link :to="d.link" class="button2" target="_blank"
						>{{ d.btn_name }} <span class="ic-icarrow-right"></span
					></router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "LetersChapters",
	props: ["theme"],
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.letersChapters {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.leterLinkItem {
				display: flex;
				border-top: 1px solid $cblacktxt;
				text-decoration: none;
				// cursor: pointer;
				@media (max-width: $ts) {
					display: block;
				}
				& + .leterLinkItem {
					margin-top: 30px;
				}
				.leter {
					width: 90px;
					height: 90px;
					flex-shrink: 0;
					background-color: $cblacktxt;
					display: flex;
					justify-content: center;
					align-items: center;
					@include h2;
					color: $cwhite;
				}
				.bd {
					flex-grow: 1;
					display: flex;
					padding-top: 10px;
					position: relative;
					@media (max-width: $tm) {
						display: block;
					}
					.caption {
						text-decoration: none;
						width: 50%;
						padding: 0 30px 0 60px;
						@include h3;
						@media (max-width: $tm) {
							width: unset;
						}
						@media (max-width: $ts) {
							padding-left: 0;
						}
					}
					.txt {
						width: 50%;
						@include t4;
						margin-bottom: 46px;
						@media (max-width: $tm) {
							padding-left: 60px;
							margin-top: 10px;
							width: unset;
							margin-bottom: 10px;
						}
						@media (max-width: $ts) {
							padding-left: 0;
						}
						* {
							margin: 0;
							& + * {
								margin-top: 16px;
							}
						}
						h2 {
							@include h2mob;
							margin: 35px 0 25px;
						}
						h3 {
							@include h3;
							margin: 35px 0 25px;
						}
						h4 {
							@include h4;
							margin: 30px 0 20px;
						}
						h5 {
							@include h5;
							margin: 25px 0 20px;
						}
						h6 {
							@include h6;
							margin: 25px 0 15px;
						}
						ol {
							list-style: none;
							counter-reset: olcounter;
							padding: 0;
							padding-left: 25px;

							li {
								@include t3l;
								margin: 16px 0;
								position: relative;
								counter-increment: olcounter;

								&::before {
									content: counter(olcounter) ".";
									position: absolute;
									top: 0px;
									left: -22px;
									@include t4;
									color: $cred1;
								}

								& > p {
									margin: 6px 0;

									& + p {
										margin-top: 16px;
									}
								}
							}
						}
						ul {
							list-style: square;

							padding: 0;
							padding-left: 25px;

							li {
								@include t3l;
								margin: 16px 0;
								position: relative;

								&::marker {
									color: $cred1;
								}
							}

							ol > li:before {
								content: counter(olcounter) ".";
								position: absolute;
								top: -1px;
								left: -30px;
								font-style: normal;
								font-weight: 600;
								font-size: 18px;
								color: #000000;
								background-color: transparent;
							}
						}
					}
					.ic-icarrow-right {
						position: absolute;
						bottom: 17px;
						right: 0;
						font-size: 20px;
						color: $cblacktxt;
						@media (max-width: $tm) {
							position: relative;
							display: flex;
							justify-content: flex-end;
							margin-top: 10px;
							bottom: unset;
							right: unset;
						}
					}
				}
				&:hover {
					border-top: 1px solid $cred1;
					.leter {
						background-color: $cred1;
					}
					.caption {
						color: $cred1;
					}
					.ic-icarrow-right {
						color: $cred1;
					}
				}
			}
			.lnkWrp {
				display: flex;
				justify-content: flex-end;
				.button2 {
					width: 250px;
					margin-top: 60px;
					max-width: unset;
					background-color: $cblacktxt;
					color: $cwhite;
					&:hover {
						background-color: $cgraybg1;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>