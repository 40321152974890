<template>
	<section class="imgSlideViewer" :class="theme">
		<div class="container">
			<div class="title">
				<h2>
					{{ d.title }}
				</h2>
			</div>
			<div class="right">
				<template v-if="isDesctop">
					<div class="imgs">
						<template v-for="(item, index) in d.list">
							<img
								:key="index"
								v-if="currentSlide == index"
								:src="path(item.image)"
								:alt="path(item.image)"
							/>
						</template>
					</div>
					<div class="txt">
						<div class="caption">{{ d.subtitle }}</div>
						<div class="slodeControls">
							<div
								class="item"
								v-for="(item, index) in d.list"
								:key="index"
								@click="currentSlide = index"
								:class="{ active: currentSlide == index }"
							>
								<span class="ic-icarrow-left"></span>
								<div>{{ item.title }}</div>
							</div>
						</div>
						<router-link
							v-if="d.btn_name && d.link"
							:to="d.link"
							class="button2"
							>{{ $t("inDetail") }} <span class="ic-icarrow-right"></span
						></router-link>
					</div>
				</template>
				<div class="mob" v-else>
					<div class="caption">{{ d.subtitle }}</div>
					<div
						class="item"
						v-for="(item, index) in d.list"
						:key="index"
						:class="{ active: openedItems[index] }"
					>
						<div class="hd" @click="openClose(index)">
							<div class="hdtxt">{{ item.title }}</div>
							<span class="ic-icclose"></span>
						</div>
						<slide-up-down :active="openedItems[index]">
							<img
								:src="require('@/assets/img/imgslide.png')"
								class="slide"
								alt=""
							/>
						</slide-up-down>
					</div>
					<router-link v-if="d.btn_name && d.link" :to="d.link" class="button2"
						>{{ $t("inDetail") }} <span class="ic-icarrow-right"></span
					></router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "ImgSlideViewer",
	props: ["theme"],
	data() {
		return {
			slides: [
				{
					name: "Хімічного контролю",
				},
				{
					name: "Біологічного контролю",
				},
				{
					name: "Молекулярно-біологічних методів біологічних методів Молекулярно біологічних методів біологічних методів",
				},
				{
					name: "Аналітична",
				},
				{
					name: "Технологічна",
				},
			],
			currentSlide: 0,

			openedItems: [],

			isDesctop: false,
		};
	},
	methods: {
		setVal() {
			// 970
			if (window.innerWidth > 970) {
				this.isDesctop = true;
			} else {
				this.isDesctop = false;
			}
		},
		openClose(i) {
			this.$set(this.openedItems, i, !this.openedItems[i]);
		},
	},
	created() {
		this.setVal();
		window.addEventListener("resize", this.setVal);
	},
	mounted() {
		this.slides.forEach(() => {
			this.openedItems.push(false);
		});
	},
};
</script>

<style lang="scss">
.imgSlideViewer {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 2fr 1fr;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			@media (max-width: $tm) {
				display: block;
			}
			.imgs {
				img {
					display: block;
					width: 100%;
					height: 500px;
					object-fit: cover;
				}
			}
			.txt {
				padding-left: 30px;
				.caption {
					@include h3;
					margin: 0 0 30px;
				}
				.slodeControls {
					.item {
						border-bottom: 1px solid #000;
						padding: 15px 35px 15px 0;
						position: relative;
						cursor: pointer;
						transition: 0.3s ease;
						div {
							@include t3;
							color: $cgray3;
						}
						.ic-icarrow-left {
							position: absolute;
							left: 0;
							top: 15px;
							transform: translate(-100%, 0);
							opacity: 0;
							transition: 0.3s ease;
						}
						&:hover {
							color: $cred1;
						}
						&.active {
							padding: 15px 0px 15px 35px;
							div {
								color: $cred1;
							}
							.ic-icarrow-left {
								color: $cred1;
								transform: translate(0, 0);
								opacity: 1;
							}
						}
					}
				}
				.button2 {
					margin-top: 60px;
					width: 100%;
					max-width: unset;
					background-color: $cblacktxt;
					color: $cwhite;
					&:hover {
						background-color: $cgraybg1;
					}
				}
			}

			.mob {
				.caption {
					@include h3;
					margin: 0 0 30px;
				}
				.item {
					& + .item {
						border-top: 1px solid #000;
					}
					.hd {
						cursor: pointer;
						@include t3;
						color: $cgray3;
						padding: 20px 35px 20px 0;
						position: relative;
						.ic-icclose {
							font-size: 19px;
							position: absolute;
							top: 50%;
							right: 0;
							color: $cblacktxt;
							transform: translate(0, -50%) rotate(45deg);
						}
					}
					.slide {
						width: 100%;
					}
					&.active {
						.hd {
							color: $cred1;
						}
					}
				}
				.button2 {
					margin-top: 60px;
					width: 100%;
					max-width: unset;
					background-color: $cblacktxt;
					color: $cwhite;
					&:hover {
						background-color: $cgraybg1;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>