<template>
	<section class="ThreeColSlimLinks" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<template v-for="(item, index) in d.list">
					<router-link
						v-if="item.link"
						:key="index"
						:to="item.link"
						class="slimLnk"
					>
						{{ item.title }}
						<span class="ic-icarrow-right"></span>
					</router-link>
					<div v-else :key="index" :to="item.link" class="slimLnk">
						{{ item.title }}
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "ThreeColSlimLinks",
	props: ["theme"],
};
</script>

<style lang="scss">
.ThreeColSlimLinks {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 1fr 1fr 1fr;
			grid-gap: 10px;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			@media (max-width: $ts) {
				display: block;
			}
			.slimLnk {
				position: relative;
				padding: 15px 15px 50px;
				@include t3;
				text-decoration: none;
				background-color: $cgray7;
				@media (max-width: $ts) {
					display: block;
					& + .slimLnk {
						margin-top: 16px;
					}
				}
				&:hover {
					background-color: $cgray6;
				}
				.ic-icarrow-right {
					position: absolute;
					bottom: 15px;
					left: 15px;
					font-size: 24px;
					color: $cred1;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>