<template>
	<section class="numbersInfo" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="left" :style="colStyle">
				<template v-for="(item, index) in d.list">
					<a
						v-if="item.link"
						:href="item.link"
						class="item"
						:key="index"
					>
						<div class="caption">{{ item.btn_name }}</div>
						<div class="txt" v-html="item.text"></div>
					</a>
					<div v-else class="item" :key="index">
						<div class="caption">{{ item.btn_name }}</div>
						<div class="txt" v-html="item.text"></div>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "NumbersInfo",
	props: ["theme"],
	computed: {
		colStyle() {
			return { gridTemplate: `auto / repeat(${this.d.list.length},1fr)` };
		},
	},
};
</script>

<style lang="scss">
.numbersInfo {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		grid-template-columns: repeat();
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.left {
			display: grid;
			grid-template: auto / 1fr 1fr 1fr;
			grid-gap: 30px;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			@media (max-width: $tm) {
				display: block;
			}
			.item {
				text-decoration: none;
				// @media (max-width: $ts) {
				// 	display: flex;
				// }
				@media (max-width: $tm) {
					display: block;
				}
				& + .item {
					@media (max-width: $ts) {
						margin-top: 45px;
					}
				}
				.caption {
					@include rt;
					color: $cred1;
					@media (max-width: $ts) {
						font-size: 27px;
					}
				}
				.txt {
					@include t4;
					margin-top: 10px;
					@media (max-width: $ts) {
						// width: 50%;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>