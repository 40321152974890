<template>
	<section class="formsLinks">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<router-link :to="d.link" target="_blank">
					{{ d.btn_name }}
					<span class="ic-icarrow-right"></span>
				</router-link>
				<router-link :to="d.link2" target="_blank">
					{{ d.btn_name2 }}
					<span class="ic-icarrow-right"></span>
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "FormsLinks",
};
</script>

<style lang="scss" scoped>
.formsLinks {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 10px;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			a {
				@include t3;
				color: $cwhite;
				display: flex;
				flex-direction: column;
				background-color: $cred2;
				text-decoration: none;
				padding: 15px;
				.ic-icarrow-right {
					margin-top: 15px;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>