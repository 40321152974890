<template>
	<section class="PressSection" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<div>
					<div class="caption">{{ d.title2 }}</div>
					<div class="lnks">
						<template v-for="(item, index) in d.list">
							<a v-if="item.link" :key="index" :href="item.link" target="_blank">
								<span :class="item.type"></span>
								<span class="txt">{{ item.title }}</span>
							</a>
						</template>
					</div>
				</div>
				<div>
					<div class="caption u">
						<a :href="pathFile(d.link_for_title3)" target="_blank">{{
							d.title3
						}}</a>
					</div>
					<div class="dv">
						<div v-html="d.text"></div>
						<div class="imgdiv">
							<p>
								<a :href="pathFile(d.link_for_image)" target="_blank"
									><img :src="path(d.image)" alt=""
								/></a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "PressSection",
	props: ["theme"],
};
</script>

<style lang="scss">
.PressSection {
	padding: 60px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 1fr 1fr;
			grid-gap: 30px;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			@media (max-width: $tm) {
				grid-template: auto / 1fr;
			}
			.caption {
				@include rt;
				color: $cred1;
				a {
					color: $cred1;
				}
				&.u {
					text-decoration: underline;
				}
			}
			.lnks {
				margin-top: 30px;
				display: flex;
				flex-wrap: wrap;
				a {
					text-decoration: none;
					margin-right: 19px;
					.ic-icfb,
					.ic-icyoutube {
						font-size: 20px;
						color: $cgraybg1;
						width: 56px;
						height: 56px;
						border-radius: 50%;
						border: 1px solid $cgray3;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.txt {
						@include t4;
						color: #000;
						margin-top: 10px;
						display: block;
					}
				}
			}
			.dv {
				display: flex;
				justify-content: space-between;
				ul {
					margin-top: 25px;
					padding-left: 20px;
					list-style: square;
					@include t4;
					li::marker {
						color: $cred1;
					}
					li + li {
						margin-top: 16px;
					}
				}
				.imgdiv {
					width: 133px;
					margin-left: 10px;
					margin-top: 10px;
					flex-shrink: 0;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>
