<template>
	<section class="visionDocs">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<div class="docItem" v-for="(item, index) in d.list" :key="index">
					<div class="date">{{ item.public_date }}</div>
					<div class="info">
						<a :href="item.category.url" target="_blank" class="docSection">{{
							item.category.name
						}}</a>
						<a :href="item.url" target="_blank" class="caption">{{
							item.name
						}}</a>
						<a
							v-if="item.file"
							:href="pathFile(item.file)"
							target="_blank"
							class="docLink"
						>
							<span class="label">{{ $t("downloadPdf") }}</span>
							<span class="ic-donwload"></span>
						</a>
					</div>
				</div>
				<div class="lnkWrp">
					<a :href="d.link" target="_blank" class="button2"
						>{{ d.btn_name }} <span class="ic-icarrow-right"></span
					></a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "VisionDocs",
	data() {
		return {
			// items: [{}, {}, {}],
		};
	},
};
</script>

<style lang="scss">
.visionDocs {
	padding: 30px 0;
	background-color: $cgray7;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.docItem {
				border-top: 1px solid $cgray4;
				display: grid;
				grid-template: auto / 244px 1fr;
				grid-gap: 20px;
				padding: 10px 0;
				@media (max-width: $tm) {
					grid-template: auto / 150px 1fr;
				}
				@media (max-width: $ts) {
					display: block;
				}
				.date {
					padding-left: 10px;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 135%;
					color: #7f8192;
					@media (max-width: $ts) {
						padding-left: 0;
					}
				}
				.info {
					.docSection {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 130%;
						text-decoration-line: underline;
						color: #7f8192;
					}
					.caption {
						display: block;
						text-decoration: none;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 120%;
						color: #111325;
						margin-top: 10px;
					}
					.docLink {
						margin-top: 10px;
						display: flex;
						align-items: center;
						text-decoration: none;
						.label {
							font-style: normal;
							font-weight: normal;
							font-size: 18px;
							line-height: 130%;
							text-decoration-line: underline;
							color: #e1251b;
						}
						.ic-donwload {
							margin-left: 15px;
							width: 35px;
							height: 35px;
							border-radius: 50%;
							background-color: $cred1;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 20px;
							color: #fff;
						}
					}
				}
			}
			.lnkWrp {
				margin-top: 15px;
				display: flex;
				justify-content: flex-end;
				.button2 {
					width: 250px;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>