<template>
	<section class="advantagesListSection" :class="theme">
		<div class="container">
			<div class="title">
				<h2 :class="headerStyle">{{ d.title }}</h2>
			</div>
			<div class="right">
				<div class="advListItem" v-for="(item, index) in d.list" :key="index">
					<div class="caption">{{ item.title }}</div>
					<div class="txt">
						<div v-html="item.text"></div>
						<div class="lnkWrp">
							<router-link
								v-if="item.btn_name && item.link"
								:to="item.link"
								class="moreLnk"
								>{{ item.btn_name }} <span class="decoLine1"></span
							></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "AdvantagesListSection",
	props: ["theme", "headerStyle"],
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.advantagesListSection {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			.advListItem {
				width: 100%;
				display: flex;
				padding: 10px 0 16px;
				border-top: 1px solid #000;
				@media (max-width: $tm) {
					display: block;
				}
				.caption {
					width: 33.33%;
					flex-shrink: 0;
					@include h3;
					@media (max-width: $tm) {
						width: unset;
						margin-bottom: 16px;
					}
				}
				.txt {
					@include t4;

					ul {
						flex-grow: 1;
						margin: 0;
						list-style: square;
						padding-left: 20px;
						li::marker {
							color: $cred1;
						}
						li + li {
							margin-top: 16px;
						}
					}
					.lnkWrp {
						display: flex;
						justify-content: flex-end;
						margin-top: 16px;
						.moreLnk {
							@include t4;
							color: $cred1;
						}
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>